import '../css/AandR.css'
import { useLayoutEffect } from 'react'
import { gsap } from 'gsap'

function AandR() {
    useLayoutEffect(() => {
        const links = gsap.utils.toArray('.ar-link')

        links.forEach((link) => {
            gsap.to(link, {
                opacity: 1,
                scrollTrigger: {
                    trigger: link,
                    start: `top 80%`,
                    toggleActions: 'play none none reverse',
                    markers: false,
                },
            })
        })
    }, [])

    return (
        <div className="ar-wrap">
            <div className="ar-label-wrap">
                <a
                    className="ar-link"
                    href="https://www.believe.com/deutschland"
                    target="_blank"
                >
                    Believe
                    {/* Deutschland <br /> Artist Service Deals */}
                </a>
            </div>
            <div className="ar-label-wrap">
                <a
                    className="ar-link"
                    href="https://selectedbase.com/"
                    target="_blank"
                >
                    Selected
                </a>
            </div>
        </div>
    )
}

export default AandR
