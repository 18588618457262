import './css/App.css'
import StickyNavbar from './components/StickyNavbar'
import MobileNav from './components/MobileNav'
import Management from './Management'
import Team from './Team'
import Footer from './Footer'
import Label from './Label'

import { useRef, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger, ScrollSmoother } from 'gsap/all'
import { SplitText } from 'gsap/src/all'
import { Power3 } from 'gsap'
import AnimatedHeading from './components/AnimatedHeading'
import LoadingScreen from './LoadingScreen'
import Modal from './components/Modal'
import CookiePopUp from './components/CookiePopUp'
import Ghost from './components/ghost/Ghost'

function App() {
    gsap.registerPlugin(ScrollTrigger, SplitText, ScrollSmoother, Power3)
    const ref = useRef(null)
    const smoothWrapper = useRef()
    const smoothContent = useRef()
    const labelSection = useRef()

    //TODO: proper loadingscreen check https://www.youtube.com/watch?v=Y7pL5wG5QOg
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        gsap.set(ref.current, { autoAlpha: 0 })
    }, [])

    useEffect(() => {
        setLoading(true)
        document.body.style.overflowY = 'hidden'
        setTimeout(() => {
            setLoading(false)
            document.body.style.overflowY = 'unset'
        }, 5000)
    }, [])

    // useEffect(() => {
    //     let smoother = ScrollSmoother.create({
    //         wrapper: smoothWrapper.current,
    //         content: smoothContent.current,
    //         smooth: 2,
    //     })

    //     return () => {
    //         smoother.kill()
    //     }
    // }, [])

    //TODO: maybe different animation for backgroundcolor
    useEffect(() => {
        gsap.set(ref.current, { autoAlpha: 1 })
        gsap.to(labelSection.current, {
            scrollTrigger: {
                trigger: labelSection.current,
                start: 'top center',
                end: '+=200',
                scrub: 2,
                markers: false,
            },
            duration: 1,
            backgroundColor: '#1e816d',
            ease: 'none',
        })
    }, [])

    // HALLOWEEN check
    const isToday = (someDate) => {
        const today = new Date()
        return (
            someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
        )
    }

    const halloween = new Date('2022-10-31')

    return (
        <div ref={smoothWrapper}>
            <div ref={smoothContent}>
                <div ref={ref} className="App">
                    {isToday(halloween) && <Ghost />}
                    <CookiePopUp />
                    <Modal />
                    <div className="content-wrap">
                        {loading ? <LoadingScreen /> : <></>}
                        <MobileNav />
                        <StickyNavbar />
                        <div className="content">
                            <section className="man-section">
                                <Management />
                            </section>
                            <section ref={labelSection} className="lab-section">
                                <Label />
                            </section>
                            <section className="team-section">
                                <AnimatedHeading
                                    lineOne={'mag'}
                                    lineTwo={'team'}
                                />
                                <Team />
                            </section>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
