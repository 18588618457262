import React, { useEffect, useState, useRef } from 'react'
import Title from './Title'
import { gsap } from 'gsap/all'
import { ScrollToPlugin } from 'gsap/all'

// Import the stylesheets
import '../css/NavbarMag.css'
import styles from '../css/StickyNavbar.module.css'

const StickyNavbar = () => {
    gsap.registerPlugin(ScrollToPlugin)

    // All states
    const [sticky, setSticky] = useState(false)
    const [navHeight, setNavHeight] = useState(0)

    // All refs
    const navbar = useRef()
    const navbarOffset = useRef()

    // Nav Buttons
    const logoButton = useRef()
    const manButton = useRef()
    const arButton = useRef()
    const tButton = useRef()

    // Mount the Event Listener on loading the site
    useEffect(() => {
        const handleScroll = () => {
            // Get the offset to the top
            const value = navbarOffset.current.clientHeight

            // Set the boolean value
            setSticky(window.pageYOffset >= value)
        }

        window.addEventListener('scroll', handleScroll)

        // Set the height of the Navbar
        setNavHeight(navbar.current.clientHeight)

        // Remove the listener when cleaning up
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    // scroll to section

    useEffect(() => {
        logoButton.current.addEventListener('click', (e) => {
            gsap.to(window, { duration: 2, scrollTo: 0 })
        })
        manButton.current.addEventListener('click', (e) => {
            gsap.to(window, { duration: 2, scrollTo: '.man-section' })
        })
        arButton.current.addEventListener('click', (e) => {
            gsap.to(window, { duration: 2, scrollTo: '.lab-section' })
        })
        tButton.current.addEventListener('click', (e) => {
            gsap.to(window, { duration: 2, scrollTo: '.team-section' })
        })
    }, [])

    return (
        <>
            <div ref={navbarOffset}>
                <Title />
            </div>
            {/* Always add the content class and only if it is sticky the other class */}
            <div
                ref={navbar}
                className={`${styles.nav_content} ${sticky && styles.sticky}`}
            >
                <nav id="navbar">
                    <img ref={logoButton} src="/magsmile.png" alt="magsmile" />
                    <ul>
                        <li>
                            <button
                                ref={manButton}
                                id="management-button"
                                type="button"
                            >
                                management
                            </button>
                        </li>
                        <li>
                            <button
                                ref={arButton}
                                id="label-button"
                                type="button"
                            >
                                label & a&r service
                            </button>
                        </li>
                        <li>
                            <button
                                ref={tButton}
                                id="team-button"
                                type="button"
                            >
                                team
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            {/* To prevent the next content to "jump" behind the navbar */}
            <div style={sticky ? { marginTop: navHeight } : {}}></div>
        </>
    )
}

export default StickyNavbar
