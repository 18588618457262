import './css/LoadingScreen.css'
import { useEffect, useRef } from 'react'
import { gsap } from 'gsap'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'

function LoadingScreen() {
    gsap.registerPlugin(DrawSVGPlugin)
    const loadingLogoWrapper = useRef()

    useEffect(() => {
        var shapes = 'rect, circle',
            tl = gsap.timeline({ repeat: true, yoyo: false })

        tl.from('.cls-5', { duration: 0.5, opacity: 0, stagger: 0.1, delay: 1 })
            .fromTo(
                shapes,
                { drawSVG: '0%' },
                {
                    duration: 2,
                    drawSVG: '100%',
                    stagger: 0.1,
                    ease: 'power3.out',
                },
                '-=1'
            )

            .to(
                shapes,
                {
                    duration: 2,
                    drawSVG: '100% 100%',
                    stagger: 0.5,
                    ease: 'power3.out',
                },
                '-=0.5'
            )
            .to('.cls-5', { duration: 0.5, opacity: 0, stagger: 0.1 }, '-=3')

        return () => {
            tl.kill()
        }
    }, [])

    return (
        <div ref={loadingLogoWrapper} className="loader-logo-wrapper">
            <div className="loader-logo">
                <svg
                    id="Ebene_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 989.66 1263.67"
                >
                    <defs>
                        <clipPath id="clippath">
                            <rect
                                className="cls-1"
                                x="67.91"
                                y="665.65"
                                width="853.83"
                                height="598.02"
                            />
                        </clipPath>
                    </defs>
                    <circle
                        className="cls-2"
                        cx="494.83"
                        cy="494.83"
                        r="469.83"
                    />
                    <g className="cls-4">
                        <circle
                            className="cls-3"
                            cx="494.83"
                            cy="540.37"
                            r="295.01"
                        />
                    </g>
                    <g>
                        <path
                            className="cls-5"
                            d="M184.89,534.22l5.33-170.2,40.78-5.88,1.73,15.69c13.41-12.99,29.6-23.78,50.95-26.86,19.75-2.85,34.54,.87,41.71,15.12,12.18-14.11,29.97-25.46,52.28-28.68,33.13-4.78,49.12,11.45,47.93,49.35l-3.67,117.28-48.11,6.94,3.43-109.44c.5-16.01-4.01-23.81-18.03-21.79-8.92,1.29-21.51,8.31-27.77,15.71-.04,1.31,.24,2.57,.19,4.2l-3.69,117.93-48.11,6.94,3.43-109.44c.5-16.01-4.01-23.81-17.71-21.84-9.88,1.42-22.16,8.72-28.73,16.18l-3.82,121.85-48.11,6.94Z"
                        />
                        <path
                            className="cls-5"
                            d="M602.83,444.68c2.87-.41,5.76-1.48,9.94-3.38l.38,28.24c-6.47,4.19-20.59,9.47-30.79,10.94-19.12,2.76-30.41-1.14-33.41-16.64-10.95,15.56-27.13,25.7-50.07,29.01-41.42,5.97-48.49-21.3-47.96-38.29,1.63-51.94,54.44-67.36,97.12-73.19l.19-6.21c.6-19.27-1.58-30.67-22.6-27.64-15.93,2.3-22.2,10.03-25.9,27.15l-40.52-2.29c6.1-32.75,26.45-54.55,71.68-61.07,44.28-6.39,66.03,8.04,64.7,50.51l-2.23,71.22c-.29,9.15,1.18,12.84,9.47,11.64Zm-103.22-5.28c-.31,9.8,3.56,18.02,18.85,15.82,11.47-1.65,22.8-9.14,28.06-15.1l.94-30.06-7.65,1.1c-22.96,3.96-39.67,10.92-40.21,28.24Z"
                        />
                        <path
                            className="cls-5"
                            d="M752.93,289.7c6.13-13.57,15.78-27.97,31.39-30.22,4.14-.6,8.57-.26,13.29,1.01l-1.01,32.34c-9.85,.44-18.15,2.29-27.48,6.56,9.21,9.73,12.93,22.53,12.5,36.25-1.2,38.22-32.18,65.45-74.87,71.61-11.15,1.61-18.45,1.68-27.6,.4-2.96,3.35-6.25,7.08-6.38,11.33-.15,4.9,2.61,7.75,14.7,6.66l47.06-3.86c35.29-2.81,55.29,6.66,54.22,40.96-1.31,41.82-39.72,63.29-88.47,70.32-60.85,8.78-79.46-4.48-78.73-28,.5-16.01,12.64-28.81,30.91-35.03l.04-1.31c-16.09-2.56-23.75-11.21-23.31-25.26,.53-16.99,11.02-27.93,26.99-41.62-14.7-6.66-23.44-21.01-22.77-42.57,1.14-36.26,28.42-66.86,75.89-73.71,18.16-2.62,33.04-1.84,43.64,4.11Zm-31.27,178.17l-46.1,3.72c-6.2,5.45-10.19,11.55-10.39,17.76-.42,13.39,17.62,14.69,39.92,11.48,21.35-3.08,36.1-8.46,36.54-22.51,.2-6.53-2.8-11.95-19.97-10.45Zm-11.54-147.93c-17.2,2.48-30.5,11.88-31.07,30.17-.53,16.99,8.42,24.8,28.81,21.86,17.2-2.48,29.29-13.65,29.81-30.32,.55-17.64-10.02-24.25-27.55-21.72Z"
                        />
                    </g>
                </svg>
            </div>
        </div>
    )
}

export default LoadingScreen
