import React from 'react'
import { useLayoutEffect } from 'react'
import Artist from './components/Artist'
import './css/Management.css'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'

export default function Management() {
    function randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    useLayoutEffect(() => {
        const images = gsap.utils.toArray('.artist-card')

        images.forEach((image) => {
            gsap.to(image, {
                opacity: 1,
                y: -randomInteger(50, 70),
                scrollTrigger: {
                    trigger: image,
                    start: `top ${randomInteger(60, 80)}%`,
                    end: 'bottom 10%',
                    scrub: 1 + randomInteger(0, 4),
                    // toggleActions: 'play none none reverse',
                    markers: false,
                },
            })
        })
    }, [])

    return (
        // TODO: connect default links remove youtube
        <div className="management-row">
            <Artist
                img="/images/NinaChuba_1.jpeg"
                alt="nina"
                name="Nina Chuba"
                mail="mailto:anna@mag-musik.de"
                spotify="https://open.spotify.com/artist/2kS9NyuATpYwjeB93h24H5?si=N68fcPv6TpSO7ebK6nQzkQ"
                soundcloud="https://soundcloud.com/ninachuba"
                instagram="https://www.instagram.com/ninachuba/"
                tiktok="https://www.tiktok.com/@ninachuba"
                facebook="https://www.facebook.com/chinanuba"
            />
            <Artist
                img="/images/paulwetz.jpeg"
                alt="paul"
                name="PaulWetz"
                mail="mailto:moritz@mag-musik.de"
                spotify="https://open.spotify.com/artist/1uaDw0T6O916Kv8TxJsQzb?si=fu_chv0kQ6-SwiGv1_rhHQ"
                soundcloud="https://soundcloud.com/Paulwetz"
                instagram="https://www.instagram.com/paulwetz/"
                tiktok="https://www.tiktok.com/@paulwetz"
                facebook="https://www.facebook.com/PaulWetz/"
                right
            />
            <Artist
                img="/images/Dillistone.jpeg"
                alt="dilli"
                name="Dillistone"
                mail="mailto:moritz@mag-musik.de"
                spotify="https://open.spotify.com/artist/7ypPN35cJ9wfF2Zs7aYS33?si=VurrPTEaTQW2m-bYgYmOLw"
                soundcloud="https://soundcloud.com/dillistone"
                instagram="https://www.instagram.com/dillistoned/"
                facebook="https://www.facebook.com/DillistoneMusic"
                right
            />
            <Artist
                img="/images/momme.jpeg"
                alt="momme"
                name="Momme Hitzemann"
                nameDescription="Songwriter"
                mail="mailto:moritz@mag-musik.de"
                spotify="https://open.spotify.com/artist/7ypPN35cJ9wfF2Zs7aYS33?si=VurrPTEaTQW2m-bYgYmOLw"
                instagram="https://www.instagram.com/hitzemannn/"
                tiktok="https://www.tiktok.com/@mommelmann/"
            />
            <Artist
                img="/images/Luzine.jpeg"
                alt="Luzine"
                name="Luzine"
                mail="mailto:moritz@mag-musik.de"
                instagram="https://www.instagram.com/luzineeee/"
                tiktok="https://www.tiktok.com/@luzineeeeeeeeee"
                right
            />
        </div>
    )
}
