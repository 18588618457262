import { gsap } from 'gsap/all'
import { useRef, useEffect, useState } from 'react'
import '../css/Modal.css'
import CloseButton from './CloseButton'
import DSGVO from './DSGVO'

function Modal() {
    const [isModalOne, setIsModalOne] = useState(false)
    const [isModalTwo, setIsModalTwo] = useState(false)

    useEffect(() => {
        const overlay = document.querySelector('.overlay')
        const toggles = document.querySelectorAll('.toggle')

        let isModalOpen = false

        const modalTL = new gsap.timeline({
            paused: true,
        })

        modalTL.to(
            overlay,
            {
                y: 0,
                ease: 'power3.out(2)',
            },
            '<'
        )

        const handleModal = (modalStatus) => {
            if (modalStatus) {
                modalTL.reverse()
                document.body.style.overflowY = 'unset'
                setTimeout(() => {
                    overlay.classList.remove('overlay--active')
                    setIsModalOne(false)
                    setIsModalTwo(false)
                }, modalTL.duration() * 1000)
            } else {
                document.body.style.overflowY = 'hidden'
                modalTL.play()
                overlay.classList.add('overlay--active')
            }
        }

        toggles.forEach((toggle) => {
            toggle.addEventListener('click', (e) => {
                if (e.target.classList.contains('impressum')) {
                    setIsModalOne(true)
                } else if (e.target.classList.contains('kontakt')) {
                    setIsModalTwo(true)
                }
                handleModal(isModalOpen)
                isModalOpen = !isModalOpen
            })
        })
    }, [])

    return (
        <div className="overlay">
            <article className="modal">
                <section className="modal__content">
                    {isModalOne ? (
                        <>
                            <h1>Impressum</h1>
                            <h2>Angaben gemäß §5 TMG</h2>
                            <p>
                                Anschrift:
                                <br />
                                mag GmbH
                                <br />
                                Gerichtstr. 84
                                <br />
                                13347 Berlin
                                <br />
                                <a href="mailto:info@mag-musik.de">
                                    info@mag-musik.de
                                </a>
                            </p>
                            <p>
                                Firmensitz:
                                <br />
                                mag GmbH
                                <br />
                                Unterbrunnerstraße 16
                                <br />
                                81475 München
                            </p>
                            <p>
                                Geschäftsführer:
                                <br />
                                Anna Goller, Moritz Goller
                            </p>
                            <p>
                                Verantwortlich für den Inhalt nach § 55 Abs. 2
                                RStV: Anna Goller, Moritz Goller
                            </p>
                            <p>
                                Trotz sorgfältiger inhaltlicher Kontrolle
                                übernehmen wir keine Haftung für die Inhalte
                                externer Links. Für den Inhalt der verlinkten
                                Seiten sind ausschliesslich deren Betreiber
                                verantwortlich.
                            </p>
                            <h2>Haftungsausschluss</h2>
                            <p>
                                1. Inhalt des OnlineangebotesDer Autor übernimmt
                                keinerlei Gewähr für die Aktualität,
                                Korrektheit, Vollständigkeit oder Qualität der
                                bereitgestellten Informationen.
                                Haftungsansprüche gegen den Autor, welche sich
                                auf Schäden materieller oder ideeller Art
                                beziehen, die durch die Nutzung oder
                                Nichtnutzung der dargebotenen Informationen bzw.
                                durch die Nutzung fehlerhafter und
                                unvollständiger Informationen verursacht wurden,
                                sind grundsätzlich ausgeschlossen, sofern
                                seitens desAutors kein nachweislich
                                vorsätzliches oder grob fahrlässiges Verschulden
                                vorliegt. Alle Angebote sind freibleibend und
                                unverbindlich. Der Autor behältes sich
                                ausdrücklich vor, Teile der Seiten oder das
                                gesamte Angebot ohne gesonderte Ankündigung zu
                                verändern, zu ergänzen, zu löschen oder die
                                Veröffentlichung zeitweise oder endgültig
                                einzustellen.
                                <br />
                                2. Verweise und LinksBei direkten oder
                                indirekten Verweisen auf fremde Webseiten
                                (“Hyperlinks”), die außerhalb des
                                Verantwortungsbereiches des Autors liegen, würde
                                eine Haftungsverpflichtung ausschließlich indem
                                Fall in Kraft treten, indemder Autor von den
                                Inhalten Kenntnis hat und es ihm technisch
                                möglich und zumutbar wäre, die Nutzung im Falle
                                rechtswidriger Inhalte zu verhindern. Der Autor
                                erklärt hiermit ausdrücklich, dass zum Zeitpunkt
                                der Linksetzung keineillegalen Inhalte auf den
                                zu verlinkenden Seiten erkennbar waren. Auf die
                                aktuelle und zukünftige Gestaltung, die Inhalte
                                oder die Urheberschaft der
                                verlinkten/verknüpften Seiten hat der Autor
                                keinerlei Einfluss. Deshalb distanziert er sich
                                hiermit ausdrücklich von allen Inhalten aller
                                verlinkten /verknüpften Seiten, die nach
                                derLinksetzung verändert wurden. Diese
                                Feststellung gilt für alle innerhalb des eigenen
                                Internetangebotes gesetzten Links undVerweise
                                sowie für Fremdeinträgein vom Autor
                                eingerichteten Gästebüchern, Diskussionsforen,
                                Linkverzeichnissen, Mailinglisten und in allen
                                anderen Formen von Datenbanken, auf deren Inhalt
                                externe Schreibzugriffe möglich sind. Für
                                illegale, fehlerhafte oder unvollständige
                                Inhalte und insbesondere für Schäden, die aus
                                der Nutzung oder Nichtnutzung solcherart
                                dargebotener Informationen entstehen, haftet
                                allein der Anbieter der Seite, auf welche
                                verwiesen wurde, nicht derjenige, der über Links
                                auf die jeweilige Veröffentlichung lediglich
                                verweist.
                                <br />
                                3. Urheber- und KennzeichenrechtDer Autor ist
                                bestrebt, in allen Publikationen die
                                Urheberrechte der verwendeten Bilder, Grafiken,
                                Tondokumente, Videosequenzen und Texte zu
                                beachten, von ihm selbst erstellte Bilder,
                                Grafiken, Tondokumente, Videosequenzen und Texte
                                zu nutzen oder auf lizenzfreie Grafiken,
                                Tondokumente, Videosequenzen und Texte
                                zurückzugreifen. Alle innerhalb des
                                Internetangebotes genannten und ggf. durch
                                Dritte geschützten Marken- und Warenzeichen
                                unterliegen uneingeschränkt den Bestimmungen
                                desjeweils gültigen Kennzeichenrechts und den
                                Besitzrechten der jeweiligen eingetragenen
                                Eigentümer. Allein aufgrund der bloßen Nennung
                                ist nicht der Schluss zu ziehen, dass
                                Markenzeichen nicht durch Rechte Dritter
                                geschützt sind! Das Copyright für
                                veröffentlichte, vom Autor selbst erstellte
                                Objekte bleibt allein beim Autor der Seiten.
                                Eine Vervielfältigung oder Verwendung solcher
                                Grafiken, Tondokumente, Videosequenzen und Texte
                                in anderen elektronischen odergedruckten
                                Publikationen ist ohne ausdrückliche Zustimmung
                                des Autors nicht gestattet.
                                <br />
                                4. DatenschutzSofern innerhalb des
                                Internetangebotes die Möglichkeit zur Eingabe
                                persönlicher oder geschäftlicher Daten
                                (Emailadressen, Namen, Anschriften) besteht, so
                                erfolgt die Preisgabe dieser Daten seitens des
                                Nutzers auf ausdrücklich freiwilliger Basis. Die
                                Inanspruchnahme und Bezahlung aller angebotenen
                                Dienste ist – soweit technisch möglich und
                                zumutbar – auch ohne Angabe solcher Daten bzw.
                                unter Angabe anonymisierter Daten oder eines
                                Pseudonyms gestattet. Die Nutzung derimRahmen
                                des Impressums oder vergleichbarer Angaben
                                veröffentlichten Kontaktdaten wie
                                Postanschriften, Telefon- und Faxnummern sowie
                                Emailadressen durch Dritte zur Übersendung von
                                nicht ausdrücklich angeforderten Informationen
                                ist nicht gestattet. Rechtliche Schritte gegen
                                die Versender von sogenannten Spam-Mails bei
                                Verstössen gegen dieses Verbot sind ausdrücklich
                                vorbehalten.
                                <br />
                                5. Rechtswirksamkeit dieses
                                HaftungsausschlussesDieser Haftungsausschluss
                                ist als Teil des Internetangebotes zu
                                betrachten, von demausauf diese Seite verwiesen
                                wurde. Sofern Teile oder einzelneFormulierungen
                                dieses Textes der geltenden Rechtslage nicht,
                                nicht mehr oder nicht vollständig entsprechen
                                sollten, bleiben die übrigen Teile des
                                Dokumentes in ihrem Inhalt und ihrer Gültigkeit
                                davon unberührt.
                            </p>
                        </>
                    ) : isModalTwo ? (
                        <>
                            <h1>Kontakt</h1>

                            <h2>
                                <a href="mailto:info@mag-musik.de">
                                    info@mag-musik.de
                                </a>
                            </h2>
                            <h2>
                                <a href="mailto:moritz@mag-musik.de">
                                    moritz@mag-musik.de
                                </a>
                            </h2>
                            <h2>
                                <a href="mailto:anna@mag-musik.de">
                                    anna@mag-musik.de
                                </a>
                            </h2>
                        </>
                    ) : (
                        <DSGVO />
                    )}
                    <CloseButton buttonClass="toggle" />
                    {/* <button className="toggle">Close</button> */}
                </section>
            </article>
        </div>
    )
}

export default Modal
