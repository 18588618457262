import './css/Team.css'

import { useRef, useLayoutEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faInstagram,
    faTiktok,
    faSpotify,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

function Team() {
    const team = useRef()
    const descriptionWrap = useRef()
    const teamImg = useRef()
    const teamAnna = useRef()
    const teamMoritz = useRef()

    const getAge = (birthDate) =>
        Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e10)

    let annaAge = getAge('1991-10-16')
    let moAge = getAge('1993-06-29')

    useLayoutEffect(() => {
        let dWrap = descriptionWrap.current
        let teamImage = teamImg.current
        let socialAnna = teamAnna.current.querySelectorAll('svg')
        let socialMoritz = teamMoritz.current.querySelectorAll('svg')

        let tl = gsap
            .timeline()
            .to(dWrap, {
                y: 0,
                opacity: 1,
                duration: 0.7,
                ease: 'power3.out',
            })
            .to(teamImage, {
                x: 0,
                duration: 0.5,
                ease: 'power3.out',
            })
            .to('#anna-goller', {
                x: 0,
                opacity: 1,
                duration: 0.4,
                ease: 'power3.out',
            })
            .to('#anna-goller h1', {
                y: 0,
                opacity: 1,
                duration: 0.3,
            })
            .to(socialAnna, {
                y: 0,
                opacity: 1,
                stagger: 0.07,
            })
            .to('#moritz-goller', {
                x: 0,
                opacity: 1,
                duration: 0.4,
                ease: 'power3.out',
            })
            .to('#moritz-goller h1', {
                y: 0,
                opacity: 1,
                duration: 0.3,
            })
            .to(socialMoritz, {
                y: 0,
                opacity: 1,
                stagger: 0.07,
            })

        ScrollTrigger.create({
            trigger: team.current,
            start: 'top center',
            end: 'top 50%',
            markers: false,
            toggleActions: 'play none none reverse ',
            animation: tl,
            invalidateOnRefresh: true,
        })
    }, [])

    return (
        // TODO: implement getAge() function
        <>
            {/* TODO: animate the img and the description */}
            <div ref={team} className="team-wrapper">
                <div className="moanna-wrapper">
                    <img
                        ref={teamImg}
                        src="/images/moanna.jpeg"
                        alt="moanna"
                    ></img>
                </div>
                <div ref={descriptionWrap} className="moanna-description">
                    <div ref={teamAnna} id="anna-goller">
                        <h1>
                            Anna <br />
                            Goller
                        </h1>
                        <p>{annaAge}, Berlin</p>
                        <hr className="first-hr" />
                        <a href="mailto:anna@mag-musik.de">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                        <a
                            href="https://www.instagram.com/annadoller/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://www.tiktok.com/@annamagmusik"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                        <a
                            href="https://open.spotify.com/user/annadoller?si=869ad530b86f4434"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faSpotify} />
                        </a>
                    </div>
                    <div ref={teamMoritz} id="moritz-goller">
                        <h1>
                            Moritz <br />
                            Goller
                        </h1>
                        <p>{moAge}, Berlin</p>
                        <hr className="first-hr" />
                        <a href="mailto:moritz@mag-musik.de">
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                        <a
                            href="https://www.instagram.com/morauch/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            href="https://tiktok.com/@momagmusik"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                        <a
                            href="https://open.spotify.com/user/moritzgoller?si=240f7dcf9bd34b99"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faSpotify} />
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Team
