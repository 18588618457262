import { useRef, useEffect } from 'react'
import { gsap } from 'gsap/all'
import '../css/MobileNav.css'

function MobileNav() {
    // Nav Buttons
    const manButton = useRef()
    const arButton = useRef()
    const tButton = useRef()

    // scroll to section

    useEffect(() => {
        manButton.current.addEventListener('click', (e) => {
            document.querySelector('#overlay-input').checked = false
            gsap.to(window, { duration: 2, scrollTo: '.man-section' })
        })
        arButton.current.addEventListener('click', (e) => {
            document.querySelector('#overlay-input').checked = false
            gsap.to(window, { duration: 2, scrollTo: '.lab-section' })
        })
        tButton.current.addEventListener('click', (e) => {
            document.querySelector('#overlay-input').checked = false
            gsap.to(window, { duration: 2, scrollTo: '.team-section' })
        })
    }, [])

    return (
        <>
            <input type="checkbox" id="overlay-input" />
            <label htmlFor="overlay-input" id="overlay-button">
                <span></span>
            </label>
            <div id="overlay">
                <ul>
                    <li>
                        <button
                            ref={manButton}
                            id="management-button"
                            type="button"
                        >
                            management
                        </button>
                    </li>
                    <li>
                        <button ref={arButton} id="label-button" type="button">
                            label & a&r service
                        </button>
                    </li>
                    <li>
                        <button ref={tButton} id="team-button" type="button">
                            team
                        </button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default MobileNav
