import { useRef, useLayoutEffect, useEffect, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { SplitText } from 'gsap/src/all'
import '../css/AnimatedHeading.css'

function AnimatedHeading({ lineOne, lineTwo }) {
    const el = useRef()
    const hrLineOne = useRef()
    const hrLineTwo = useRef()
    const headingOne = useRef()
    const headingTwo = useRef()

    useLayoutEffect(() => {
        let line = hrLineOne.current
        let line2 = hrLineTwo.current
        let heading = headingOne.current
        let heading2 = headingTwo.current
        let h1SplitText = new SplitText(heading, { type: 'chars' })
        let h2SplitText = new SplitText(heading2, { type: 'chars' })
        let chars = h1SplitText.chars
        let chars2 = h2SplitText.chars

        chars.concat(chars2).forEach((element) => {
            element.style.opacity = '0'
            element.style.transform = 'translateY(100%)'
        })

        let tl = gsap
            .timeline()
            .to(chars, {
                y: 0,
                stagger: 0.07,
                opacity: 1,
                duration: 0.3,
                ease: 'power3.out',
            })
            .to(line, { x: 0, ease: 'power3.out' }, '-=0.2')
            .to(line2, { x: 0, ease: 'power3.out' }, '-=0.3')
            .to(
                chars2,
                {
                    y: 0,
                    stagger: 0.07,
                    opacity: 1,
                    duration: 0.3,
                    ease: 'power3.out',
                },
                '-=0.5'
            )

        ScrollTrigger.create({
            trigger: el.current,
            start: 'top center',
            end: 'top 50%',
            markers: false,
            toggleActions: 'play none none reverse ',
            animation: tl,
            invalidateOnRefresh: true,
        })
    }, [])

    return (
        <div ref={el} className="container reveal">
            <div className="flexbox">
                <div className="c1">
                    <h1 ref={headingOne} className="anim-heading-line">
                        {lineOne || 'title1'}
                    </h1>
                </div>
                <div className="c2">
                    <div ref={hrLineOne} className="hr-line"></div>
                </div>
            </div>
            <div className="flexbox">
                <div className="c3">
                    <div ref={hrLineTwo} className="hr-line"></div>
                </div>
                <div className="c4">
                    <h1 ref={headingTwo} className="anim-heading-line">
                        {lineTwo || 'title2'}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default AnimatedHeading
