import SpotifyPlayer from 'react-spotify-player'
import AandR from './components/AandR'
import AnimatedHeading from './components/AnimatedHeading'
import './css/Label.css'
import { useLayoutEffect } from 'react'
import { gsap } from 'gsap'

function Label() {
    useLayoutEffect(() => {
        gsap.to('.spotify-wrap', {
            opacity: 1,
            y: 0,
            scrollTrigger: {
                trigger: '.spotify-wrap',
                start: `top 80%`,
                toggleActions: 'play none none reverse',
                markers: false,
            },
        })
    })

    return (
        <>
            <AnimatedHeading lineOne={'New'} lineTwo={'Releases'} />
            <div className="spotify-wrap">
                <SpotifyPlayer
                    uri="https://open.spotify.com/playlist/7IMt8kJZeOsD3r5VVUOiSk?si=21359e333bcf405e"
                    size={{ width: '100%', height: '100%' }}
                    view="list"
                    theme="black"
                />
            </div>
            <AnimatedHeading lineOne={'a&r'} lineTwo={'service'} />
            <AandR />
        </>
    )
}

export default Label
