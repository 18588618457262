import React from 'react'
import { useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faSoundcloud,
    faInstagram,
    faFacebook,
    faYoutube,
    faSpotify,
    faTiktok,
} from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

export default function Artist(props) {
    const name = props.name
    const mail = props.mail
    const instagram = props.instagram
    const tiktok = props.tiktok
    const spotify = props.spotify
    const soundcloud = props.soundcloud
    const facebook = props.facebook
    const youtube = props.youtube

    const nameDescription = props.nameDescription

    const artistCard = useRef()

    // XXX: remove this!
    const handleButton = () => {
        document.querySelector('.artist-card')
    }

    useEffect(() => {
        let artist = artistCard.current

        if (name.length > 12) {
            artist.querySelector('h1').classList.add('smaller-artist-name')
        }
    }, [])

    return (
        //TODO: links for social media missing and animation
        <div
            className={props.right ? 'artist-card right' : 'artist-card'}
            // XXX: remove this!
            onClick={handleButton}
            ref={artistCard}
        >
            <img src={props.img} className="artist-img" alt={props.alt}></img>
            <div className="artist-overlay"></div>
            <div className="artist-description">
                <h5 className="artist-mag">mag management</h5>

                <h1 className="artist-name">{name}</h1>

                {nameDescription && (
                    <h6 style={{ margin: 0 }}>{nameDescription}</h6>
                )}

                <div className="fa-icons">
                    {mail && (
                        <a href={mail}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </a>
                    )}

                    {instagram && (
                        <a href={instagram} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    )}

                    {spotify && (
                        <a href={spotify} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faSpotify} />
                        </a>
                    )}

                    {soundcloud && (
                        <a href={soundcloud} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faSoundcloud} />
                        </a>
                    )}

                    {tiktok && (
                        <a href={tiktok} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faTiktok} />
                        </a>
                    )}

                    {facebook && (
                        <a href={youtube} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} />
                        </a>
                    )}

                    {youtube && (
                        <a href={youtube} target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faYoutube} />
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}
