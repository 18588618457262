import React from 'react'

export default function DSGVO() {
    return (
        <div>
            <div className="ce_text privacy_item general_part1 block">
                <h1>Datenschutzerklärung</h1>
                <div className="text">
                    <p>
                        Mit dieser Datenschutzerklärung möchten wir Sie über
                        Art, Umfang und Zweck der Verarbeitung von
                        personenbezogenen Daten (im Folgenden auch nur als
                        "Daten" bezeichnet) aufklären. Personenbezogene Daten
                        sind alle Daten, die einen persönlichen Bezug zu Ihnen
                        aufweisen, z. B. Name, Adresse, E-Mail-Adresse oder Ihr
                        Nutzerverhalten. Die Datenschutzerklärung gilt für alle
                        von uns vorgenommene Daten-Verarbeitungsvorgänge sowohl
                        im Rahmen unserer Kerntätigkeit als auch für die von uns
                        vorgehaltenen Online-Medien.
                    </p>
                </div>
            </div>
            <div className="ce_text privacy_item contact_responsible block">
                <div className="text">
                    <h2>
                        Wer bei uns für die Datenverarbeitung verantwortlich ist
                    </h2>
                    <p>Verantwortlich für die Datenverarbeitung ist:</p>
                </div>
            </div>
            <p>
                mag GmbH
                <br />
                Gerichtstr. 84
                <br />
                13347 Berlin
                <br />
                Deutschland
                <br />
                info@mag-musik.de
                <br />
            </p>
            <div className="ce_text privacy_item general_part3 block">
                <div className="text">
                    <h2>
                        Verarbeitung Ihrer Daten im Rahmen der Kerntätigkeit
                        unseres Unternehmens
                    </h2>
                    <p>
                        Sofern Sie unser Kunde oder Geschäftspartner sind oder
                        sich für unsere Leistungen interessieren, richtet sich
                        Art, Umfang und Zweck der Verarbeitung Ihrer Daten nach
                        dem zwischen uns bestehenden vertraglichen bzw.
                        vorvertraglichen Beziehungen. In diesem Sinne gehören zu
                        den von uns verarbeiteten Daten all diejenigen Daten,
                        die zum Zwecke der Inanspruchnahme der vertraglichen
                        bzw. vorvertraglichen Leistungen durch Sie
                        bereitgestellt werden bzw. wurden und die zur Abwicklung
                        Ihrer Anfrage oder des zwischen uns geschlossenen
                        Vertrages benötigt werden. Sofern sich aus den weiteren
                        Hinweisen dieser Datenschutzerklärung nichts anderes
                        ergibt, beschränkt sich die Verarbeitung Ihrer Daten
                        sowie deren Weitergabe an Dritte auf diejenigen Daten,
                        die zur Beantwortung Ihrer Anfragen und/oder zur
                        Erfüllung des zwischen Ihnen und uns geschlossenen
                        Vertrages, zur Wahrung unserer Rechte sowie zur
                        Erfüllung gesetzlicher Pflichten erforderlich und
                        zweckmäßig sind. Welche Daten hierfür erforderlich sind,
                        teilen wir Ihnen vor oder im Rahmen der Datenerhebung
                        mit. Soweit wir zur Erbr/ingung unserer Leistungen
                        Drittanbieter einsetzen, gelten die Datenschutzhinweise
                        der jeweiligen Drittanbieter.
                    </p>
                    <p>
                        <strong>Betroffene Daten:</strong>
                    </p>
                    <ul>
                        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
                        <li>
                            Zahlungsdaten (bspw. Bankverbindungsdaten,
                            Rechnungen)
                        </li>
                        <li>
                            Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer,
                            Postanschrift)
                        </li>
                        <li>
                            Vertragsdaten (bspw. Vertragsgegenstand,
                            Vertragsdauer)
                        </li>
                    </ul>
                    <p>
                        <strong>Betroffene Personen: </strong>Interessenten,
                        Geschäfts- und Vertragspartner
                    </p>
                    <p>
                        <strong>Verarbeitungszweck: </strong>Abwicklung
                        vertraglicher Leistungen, Kommunikation sowie
                        Beantwortung von Kontaktanfragen, Büro und
                        Organisationsverfahren
                    </p>
                    <p>
                        <strong>Rechtsgrundlage:</strong> Vertragserfüllung und
                        vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO,
                        rechtliche Verpflichtung, Art. 6 Abs. 1 lit. c DSGVO,
                        berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
                    </p>
                </div>
            </div>
            <div className="ce_text privacy_item general_part3 block">
                <div className="text">
                    <h2>Ihre Rechte nach der DSGVO</h2>
                    <p>
                        Nach der DSGVO stehen Ihnen die nachfolgend aufgeführten
                        Rechte zu, die Sie jederzeit bei dem in Ziffer 1. dieser
                        Datenschutzerklärung genannten Verantwortlichen geltend
                        machen können:
                    </p>
                    <ul>
                        <li>
                            <strong>Recht auf Auskunft:</strong> Sie haben das
                            Recht, von uns Auskunft darüber zu verlangen, ob und
                            welche Daten wir von Ihnen verarbeiten.
                        </li>
                        <li>
                            <strong>Recht auf Berichtigung:</strong> Sie haben
                            das Recht, die Berichtigung unrichtiger oder
                            Vervollständigung unvollständiger Daten zu
                            verlangen.
                        </li>
                        <li>
                            <strong>Recht auf Löschung:</strong> Sie haben das
                            Recht, die Löschung Ihrer Daten zu verlangen.
                        </li>
                        <li>
                            <strong>Recht auf Einschränkung:</strong> Sie haben
                            in bestimmten Fällen das Recht zu verlangen, dass
                            wir Ihre Daten nur noch eingeschränkt bearbeiten.
                        </li>
                        <li>
                            <strong>Recht auf Datenübertragbarkeit:</strong> Sie
                            haben das Recht zu verlangen, dass wir Ihnen oder
                            einem anderen Verantwortlichen Ihre Daten in einem
                            strukturierten, gängigen und maschinenlesebaren
                            Format übermitteln.
                        </li>
                        <li>
                            <strong>Beschwerderecht</strong>: Sie haben das
                            Recht, sich bei einer Aufsichtsbehörde zu
                            beschweren. Zuständig ist die Aufsichtsbehörde Ihres
                            üblichen Aufenthaltsortes, Ihres Arbeitsplatzes oder
                            unseres Firmensitzes.
                        </li>
                    </ul>
                    <h3>Widerrufsrecht</h3>
                    <p>
                        Sie haben das Recht, die von Ihnen erteilte Einwilligung
                        zur Datenverarbeitung jederzeit zu widerrufen.
                    </p>
                    <h3>Widerspruchsrecht</h3>
                    <p>
                        Sie haben das Recht, jederzeit gegen die Verarbeitung
                        Ihrer Daten, die wir auf unser berechtigtes Interesse
                        nach Art. 6 Abs. 1 lit. f DSGVO stützen, Widerspruch
                        einzulegen. Sofern Sie von Ihrem Widerspruchsrecht
                        Gebr/auch machen, bitten wir Sie um die Darlegung der
                        Gründe. Wir werden Ihre personenbezogenen Daten dann
                        nicht mehr verarbeiten, es sei denn, wir können Ihnen
                        gegenüber nachweisen, dass zwingende schutzwürdige
                        Gründe an der Datenverarbeitung Ihre Interessen und
                        Rechte überwiegen.
                    </p>
                    <p>
                        <span style={{ textDecoration: 'underline' }}>
                            <strong>
                                Unabhängig vom vorstehend Gesagten, haben Sie
                                das jederzeitige Recht, der Verarbeitung Ihrer
                                personenbezogenen Daten für Zwecke der Werbung
                                und Datenanalyse zu widersprechen.
                            </strong>
                        </span>
                    </p>
                    <p>
                        Ihren Widerspruch richten Sie bitte an die oben
                        angegebene Kontaktadresse des Verantwortlichen.
                    </p>
                    <h2>Wann löschen wir Ihre Daten?</h2>
                    <p>
                        Wir löschen Ihre Daten dann, wenn wir diese nicht mehr
                        br/auchen oder Sie uns dies vorgeben. Das bedeutet, dass
                        - sofern sich aus den einzelnen Datenschutzhinweisen
                        dieser Datenschutzerklärung nichts anderes ergibt - wir
                        Ihre Daten löschen,
                    </p>
                    <ul>
                        <li>
                            wenn der Zweck der Datenverarbeitung weggefallen ist
                            und damit die jeweilige in den einzelnen
                            Datenschutzhinweisen genannte Rechtsgrundlage nicht
                            mehr besteht, also bspw.
                            <ul>
                                <li>
                                    nach Beendigung der zwischen uns bestehenden
                                    vertraglichen oder mitgliedschaftlichen
                                    Beziehungen (Art. 6 Abs. 1 lit. a DSGVO)
                                    oder
                                </li>
                                <li>
                                    nach Wegfall unseres berechtigten Interesses
                                    an der weiteren Verarbeitung oder
                                    Speicherung Ihrer Daten (Art. 6 Abs. 1 lit.
                                    f DSGVO),
                                </li>
                            </ul>
                        </li>
                        <li>
                            wenn Sie von Ihrem Widerrufsrecht Gebr/auch machen
                            und keine anderweitige gesetzliche Rechtsgrundlage
                            für die Verarbeitung im Sinne von Art. 6 Abs. 1 lit.
                            b-f DSGVO eingreift,
                        </li>
                        <li>
                            wenn Sie vom Ihrem Widerspruchsrecht Gebr/auch
                            machen und der Löschung keine zwingenden
                            schutzwürdigen Gründe entgegenstehen.
                        </li>
                    </ul>
                    <p>
                        Sofern wir (bestimmte Teile) Ihre(r) Daten jedoch noch
                        für andere Zwecke vorhalten müssen, weil dies etwa
                        steuerliche Aufbewahrungsfristen (in der Regel 6 Jahre
                        für Geschäftskorrespondenz bzw. 10 Jahre für
                        Buchungsbelege) oder die Geltendmachung, Ausübung oder
                        Verteidigung von Rechtsansprüchen aus vertraglichen
                        Beziehungen (bis zu vier Jahren) erforderlich machen
                        oder die Daten zum Schutz der Rechte einer anderen
                        natürlichen oder juristischen Person gebr/aucht werden,
                        löschen wir (den Teil) Ihre(r) Daten erst nach Ablauf
                        dieser Fristen. Bis zum Ablauf dieser Fristen
                        beschränken wir die Verarbeitung dieser Daten jedoch auf
                        diese Zwecke (Erfüllung der Aufbewahrungspflichten).
                    </p>
                </div>
            </div>
            <div className="ce_text privacy_item general_part2 block">
                <div className="text">
                    <h2>Cookies</h2>
                    <p>
                        Unsere Internetseite nutzt Cookies. Bei Cookies handelt
                        es sich um kleine Textdateien, bestehend aus einer Reihe
                        von Zahlen und Buchstaben, die auf dem von Ihnen
                        genutzten Endgerät abgelegt und gespeichert werden.
                        Cookies dienen vorrangig dazu, Informationen zwischen
                        dem von Ihnen genutzten Endgerät und unserer Webseite
                        auszutauschen. Hierzu gehören u. a. die
                        Spracheinstellungen auf einer Webseite, der Login-Status
                        oder die Stelle, an der ein Video geschaut wurde.
                    </p>
                    <p>
                        Beim Besuch unserer Webseiten werden zwei Typen von
                        Cookies eingesetzt:
                    </p>
                    <ul>
                        <li>
                            <strong>
                                Temporäre Cookies (Session Cookies):
                            </strong>{' '}
                            Diese speichern eine sogenannte Session-ID, mit
                            welcher sich verschiedene Anfragen Ihres br/owsers
                            der gemeinsamen Sitzung zuordnen lassen. Die
                            Session-Cookies werden gelöscht, wenn Sie sich
                            ausloggen oder Ihren br/owser schließen.
                        </li>
                        <li>
                            <strong>Permanente Cookies: </strong>Permanente
                            Cookies bleiben auch nach dem Schließen des
                            br/owsers gespeichert. Dadurch erkennt unsere
                            Webseite Ihren Rechner wieder, wenn Sie auf unsere
                            Webseite zurückkehren. In diesen Cookies werden
                            beispielsweise Informationen zu Spracheinstellungen
                            oder Log-In-Informationen gespeichert. Außerdem kann
                            mit diesen Cookies Ihr Surfverhalten dokumentiert
                            und gespeichert werden. Diese Daten können zu
                            Statistik-, Marketing- und Personalisierungs-Zwecken
                            verwendet werden.
                        </li>
                    </ul>
                    <p>
                        Neben der vorstehenden Einteilung können Cookies auch im
                        Hinblick auf ihren Einsatzzweck unterschieden werden:
                    </p>
                    <ul>
                        <li>
                            <strong>Notwendige Cookies:</strong> Dies sind
                            Cookies, die für den Betrieb unserer Webseite
                            unbedingt erforderlich sind, um Logins oder
                            Warenkörbe für die Dauer Ihrer Sitzung zu speichern
                            oder Cookies, die aus Sicherheitsgründen gesetzt
                            werden.
                        </li>
                        <li>
                            <strong>
                                Statistik-, Marketing- und
                                Personalisierungs-Cookies:
                            </strong>{' '}
                            Dies sind Cookies, die für Analysezwecke oder die
                            Reichweitenmessung eingesetzt werden. Über solche
                            "Tracking"-Cookies können insbesondere Informationen
                            zu eingegebenen Suchbegriffen oder die Häufigkeit
                            von Seitenaufrufen gespeichert sein. Daneben kann
                            aber auch das Surfverhalten eines einzelnen Nutzers
                            (z. B. Betrachten bestimmter Inhalte, Nutzen von
                            Funktionen etc.) in einem Nutzerprofil gespeichert
                            werden. Solche Profile werden genutzt, um Nutzern
                            Inhalte anzuzeigen, die deren potentiellen
                            Interessen entsprechen. Soweit wir Dienste nutzen,
                            über die Cookies zu Statistik-, Marketing- und
                            Personalisierungs-Zwecken auf Ihrem Endgerät
                            gespeichert werden, informieren wir Sie hierzu
                            gesondert in den nachfolgenden Abschnitten unserer
                            Datenschutzerklärung oder im Rahmen der Einholung
                            Ihrer Einwilligung.
                        </li>
                    </ul>
                    <p>
                        <strong>Betroffene Daten:</strong>
                    </p>
                    <ul>
                        <li>
                            Nutzungsdaten (bspw. Zugriffszeiten, angeklickte
                            Webseiten)
                        </li>
                        <li>
                            Kommunikationsdaten (bspw. Informationen über das
                            genutzte Gerät, IP-Adresse).
                        </li>
                    </ul>
                    <p>
                        <strong>Betroffene Personen: </strong>Nutzer unserer
                        Onlineangebote
                    </p>
                    <p>
                        <strong>Verarbeitungszweck: </strong>Ausspielen unserer
                        Internetseiten, Gewährleistung des Betriebs unserer
                        Internetseiten, Verbesserung unseres Internetangebotes,
                        Kommunikation und Marketig
                    </p>
                    <p>
                        <strong>
                            Rechtsgrundlage:
                            <br />
                            Berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
                        </strong>
                        <br />
                        Sofern wir von Ihnen keine Einwilligung in das Setzen
                        der Cookies einholen, stützen wir die Verarbeitung Ihrer
                        Daten auf unser berechtigtes Interesse, die Qualität und
                        Benutzerfreundlichkeit unseres Internetauftritts,
                        insbesondere der Inhalte und Funktionen zu verbessern.
                        Sie haben über die Sicherheitseinstellungen Ihres
                        br/owsers, dem Einsatz der von uns im Rahmen unseres
                        berechtigten Interesses gesetzten Cookies zu
                        widersprechen. Dort haben Sie die Möglichkeit
                        festzulegen, ob Sie etwa von vornherein keine oder nur
                        auf Nachfrage Cookies akzeptieren oder aber festlegen,
                        dass Cookies nach jedem Schließen Ihres br/owsers
                        gelöscht werden. Werden Cookies für unsere Webseite
                        deaktiviert, können möglicherweise nicht mehr alle
                        Funktionen der Webseite vollumfänglich genutzt werden.
                    </p>
                </div>
            </div>
            <div className="ce_text privacy_item external_hosting block">
                <div className="text">
                    <h2>Webhosting</h2>
                    <p>
                        Wir bedienen uns zum Vorhalten unserer Internetseiten
                        eines Anbieters, auf dessen Server unsere Internetseiten
                        gespeichert und für den Abr/uf im Internet verfügbar
                        gemacht werden (Hosting). Hierbei können von dem
                        Anbieter all diejenigen über den von Ihnen genutzten
                        br/owser übertragenen Daten verarbeitet werden, die bei
                        der Nutzung unserer Internetseiten anfallen. Hierzu
                        gehören insbesondere Ihre IP-Adresse, die der Anbieter
                        benötigt, um unser Online-Angebot an den von Ihnen
                        genutzten br/owser ausliefern zu können sowie sämtliche
                        von Ihnen über unsere Internetseite getätigten Eingaben.
                        Daneben kann der von uns genutzte Anbieter&nbsp;&nbsp;
                    </p>
                    <ul>
                        <li>
                            das Datum und die Uhrzeit des Zugriffs auf unsere
                            Internetseite
                        </li>
                        <li>
                            Zeitzonendifferenz zur Greenwich Mean Time (GMT)
                        </li>
                        <li>Zugriffsstatus (HTTP-Status)</li>
                        <li>die übertragene Datenmenge</li>
                        <li>
                            der Internet-Service-Provider des zugreifenden
                            Systems
                        </li>
                        <li>
                            der von Ihnen verwendete br/owsertyp und dessen
                            Version
                        </li>
                        <li>das von Ihnen verwendete Betriebssystem</li>
                        <li>
                            die Internetseite, von welcher Sie gegebenenfalls
                            auf unsere Internetseite gelangt sind
                        </li>
                        <li>
                            die Seiten bzw. Unterseiten, welche Sie auf unserer
                            Internetseite besuchen.
                        </li>
                    </ul>
                    <p>
                        erheben. Die vorgenannten Daten werden als Logfiles auf
                        den Servern unseres Anbieters gespeichert. Dies ist
                        erforderlich, um die Stabilität und Sicherheit des
                        Betriebs unserer Internetseite zu gewährleisten.
                    </p>
                    <p className="invisible">&nbsp;</p>
                    <p>
                        <strong>Betroffene Daten:</strong>
                    </p>
                    <ul>
                        <li>Inhaltsdaten (bspw. Posts, Fotos, Videos)</li>
                        <li>
                            Nutzungsdaten (bspw. Zugriffszeiten, angeklickte
                            Webseiten)
                        </li>
                        <li>
                            Kommunikationsdaten (bspw. Informationen über das
                            genutzte Gerät, IP-Adresse)
                        </li>
                    </ul>
                    <p>
                        <strong>Betroffene Personen: </strong>Nutzer unserer
                        Internetpräsenz
                    </p>
                    <p>
                        <strong>Verarbeitungszweck: </strong>Ausspielen unserer
                        Internetseiten, Gewährleistung des Betriebs unserer
                        Internetseiten
                    </p>
                    <p>
                        <strong>Rechtsgrundlage:</strong> Berechtigtes
                        Interesse, Art. 6 Abs. 1 lit. f DSGVO
                    </p>
                    <p>
                        <strong>Von uns beauftragte(r) Webhoster:</strong>
                    </p>
                </div>
            </div>
            <div className="ce_text block">
                <div className="text">
                    <p>
                        <strong>checkdomain</strong>
                    </p>
                    <p>
                        Dienstanbieter: checkdomain
                        <br />
                        Internetseite: <br />
                        Datenschutzerklärung:{' '}
                        <a href="www.checkdomain.de" target="_blank">
                            www.checkdomain.de
                        </a>
                    </p>
                </div>
            </div>
            <div className="ce_text block">
                <div className="text">
                    <h2>Kontaktaufnahme</h2>
                    <p>
                        Soweit Sie uns über E-Mail, Soziale Medien, Telefon,
                        Fax, Post, unser Kontaktformular oder sonstwie
                        ansprechen und uns hierbei personenbezogene Daten wie
                        Ihren Namen, Ihre Telefonnummer oder Ihre E-Mail-Adresse
                        zur Verfügung stellen oder weitere Angaben zur Ihrer
                        Person oder Ihrem Anliegen machen, verarbeiten wir diese
                        Daten zur Beantwortung Ihrer Anfrage im Rahmen des
                        zwischen uns bestehenden vorvertraglichen oder
                        vertraglichen Beziehungen.
                    </p>
                    <p>
                        <strong>Betroffene Daten:</strong>
                    </p>
                    <ul>
                        <li>Bestandsdaten (bspw. Namen, Adressen)</li>
                        <li>
                            Kontakdaten (bspw. E-Mail-Adresse, Telefonnummer,
                            Postanschrift)
                        </li>
                        <li>Inhaltsdaten (Texte, Fotos, Videos)</li>
                        <li>
                            Vertragsdaten (bspw. Vertragsgegenstand,
                            Vertragsdauer)
                        </li>
                    </ul>
                    <p>
                        <strong>Betroffene Personen: </strong>Interessenten,
                        Kunden, Geschäfts- und Vertragspartner
                    </p>
                    <p>
                        <strong>Verarbeitungszweck: </strong>Kommunikation sowie
                        Beantwortung von Kontaktanfragen, Büro und
                        Organisationsverfahren
                    </p>
                    <p>
                        <strong>Rechtsgrundlage:</strong> Vertragserfüllung und
                        vorvertragliche Anfragen, Art. 6 Abs. 1 lit. b DSGVO,
                        berechtigtes Interesse, Art. 6 Abs. 1 lit. f DSGVO
                    </p>
                </div>
            </div>
            <div className="ce_text privacy_item general_part4 block">
                <div className="text">
                    <h2>Sicherheitsmaßnahmen</h2>
                    <p>
                        Wir treffen im Übr/igen technische und organisatorische
                        Sicherheitsmaßnahmen nach dem Stand der Technik, um die
                        Vorschriften der Datenschutzgesetze einzuhalten und Ihre
                        Daten gegen zufällige oder vorsätzliche Manipulationen,
                        teilweisen oder vollständigen Verlust, Zerstörung oder
                        gegen den unbefugten Zugriff Dritter zu schützen.
                    </p>
                    <h2>Aktualität und Änderung dieser Datenschutzerklärung</h2>
                    <p>
                        Diese Datenschutzerklärung ist aktuell gültig und hat
                        den Stand Oktober 2022. Aufgrund geänderter gesetzlicher
                        bzw. behördlicher Vorgaben kann es notwendig werden,
                        diese Datenschutzerklärung anzupassen.
                    </p>
                    <p>
                        <strong>
                            Diese Datenschutzerklärung wurde mit Hilfe des
                            Datenschutz-Generators von SOS Recht erstellt. SOS
                            Recht ist ein Angebot der Mueller.legal
                            Rechtsanwälte Partnerschaft mit Sitz in Berlin.
                        </strong>
                    </p>
                </div>
            </div>
        </div>
    )
}
